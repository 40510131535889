import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useCompany = () => {
  const toast = useToast();

  const deleteCompany = async (company) => {
    try {
      await store.dispatch('company/remove', company.id);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error deleting the company',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const fetchCompany = async (companyId) => {
    const company = await store.dispatch('company/get', companyId);
    store.commit('company/storeCompany', company);
    return company;
  };

  const getCompanyList = async () => {
    try {
      return await store.dispatch('company/getList');
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error getting the list of companies',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const createCompany = async (company) => {
    try {
      return await store.dispatch('company/create', company);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const updateCompany = async (company) => {
    try {
      return await store.dispatch('company/update', company);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  return {
    //Method
    deleteCompany,
    fetchCompany,
    getCompanyList,
    updateCompany,
    createCompany,
  };
};

export default useCompany;
